import { extractPurpose } from "@components/GDPR/utils";
import { getStoreKey } from "@utils/functions";
import { escapeReact } from "@utils/react";
import FooterLinkSections from "./FooterLinkSections";

export default pageParams => {
  const { i18n, pathfinder } = pageParams;

  const { widgets } = FooterLinkSections(pageParams);

  const cookies = widgets.map(item => item.id || item.predefined);

  const { purpose, expiry, cookieDatabase } =
    i18n.components.GDPRCookieController;

  const cookieSiteId = i18n.components.GDPRCookieController.THIS_SITE;
  const cookieSiteKey = getStoreKey(false) + "-*";

  const purposeProps = {
    purpose: purpose.map(item => ({
      id: item.KEY,
      title: item.TITLE,
      description: item.DESCRIPTION
    })),
    expiry,
    cookieDatabase,
    cookieSiteId,
    cookieSiteKey,
    cookies
  };

  const _purpose = extractPurpose(purposeProps);

  const _i18n = i18n.pages.CookiePolicy;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const transformPara = params => ({
    title: params.TITLE,
    text: escapeReactRoute(params.TEXT)
  });

  const extractCookies = obj => {
    const category = obj.DYNAMIC_KEY;

    if (obj.DYNAMIC && category) {
      const item = _purpose.find(item => item.id === category);

      if (item) {
        const mapping = obj.DYNAMIC_COL_MAPPING.map(item => item.KEY);

        return item.items.map(cookie => mapping.map(key => cookie[key]));
      }
    }

    return (obj.table.rows || []).map(item => item.cols.map(col => col.TEXT));
  };

  const transformTable = obj => {
    return {
      lead: {
        title: obj.lead.TITLE,
        text: obj.lead.TEXT
      },
      table: {
        visible: obj.table.visible,
        header: (obj.table.header || []).map(item => item.TITLE),
        rows: extractCookies(obj)
      }
    };
  };

  return {
    lead: transformPara({
      TITLE: _i18n.TITLE,
      TEXT: _i18n.LEAD
    }),
    whatIsCookie: transformPara(_i18n.WHAT_IS_COOKIE),
    whatCookieAreUsed: transformPara(_i18n.WHAT_COOKIE_ARE_USED),
    cookieTypeTables: _i18n.cookieTypes.map(transformTable),
    cookieHandling: transformPara(_i18n.COOKIE_HANDLING),
    policyChanges: transformPara(_i18n.POLICY_CHANGES),
    contactInfo: transformPara(_i18n.CONTACT_INFO)
  };
};
