import React from "react";
import TitledParagraph from "@components-core/TitledParagraph";
import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.BuyInfo;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const items = [
    {
      img: {
        src: imgParams.utils.get(imgParams.svgDir, "/pay-method.svg"),
        cloudinary: imgParams.cloudinary
      },
      default_colspan: 2,
      colspan: 6
    },
    {
      icon: ["fas", "shipping-fast"],
      default_colspan: 2,
      colspan: 6
    },
    {
      img: {
        src: imgParams.utils.get(imgParams.svgDir, "/return-box.svg"),
        cloudinary: imgParams.cloudinary
      },
      default_colspan: 2,
      colspan: 6
    },
    {
      img: {
        src: imgParams.utils.get(imgParams.svgDir, "/stared-lock.svg"),
        cloudinary: imgParams.cloudinary
      },
      default_colspan: 2,
      colspan: 6
    },
    {
      icon: "exclamation-triangle",
      default_colspan: 2,
      colspan: 6
    },
    {
      icon: "search",
      default_colspan: 2,
      colspan: 6
    }
  ];

  const result = {
    title: _i18n.TITLE,
    lead: { title: _i18n.KICKER, text: escapeReactRoute(_i18n.LEAD) },
    items: items.map((item, i) => ({
      ...item,
      title: _i18n.items[i].TITLE,
      href: _i18n.items[i].URL,
      paragraphs: (_i18n.items[i].paragraphs || []).map(para => ({
        title: para.TITLE,
        text: para.TEXT
      }))
    }))
    .concat(
        ..._i18n.items.slice(6).map((item, i) => ({
          icon: "info-circle",
          default_colspan: 2,
          colspan: 6,
          title: item.TITLE,
          href: null,
          paragraphs: (item.paragraphs || []).map(para => ({
            title: para.TITLE,
            text: para.TEXT
          }))
        }))
      )	  
  };

  result.children = (
    <TitledParagraph title={result.lead.title} text={result.lead.text} />
  );

  return result;
};
