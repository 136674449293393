//import { passModuleArgs } from "@templates/utils";
import Site from "./common/Site";

// SITE-DEPENDENT
import DefaultSite from "./_default";
import { mergedGraphql } from "./_utils";

/**
 * @description Get the site specific GraphQL configuration
 * @param {number} siteId The site Id
 * @returns {Object} Returns the GraphQL configuration object, NULL if default is used instead
 */
const graphql = siteId => null;
// const graphql = siteId => ({
//   endpoint: "http://graphql-sites.lsbolagen.se/site-" + siteId
// });

/**
 * @description
 * @param {number} siteId The site Id
 * @param {Object} customModules
 * @param {Object} config The configuration
 * @returns {Object}
 */
const options = (siteId, customModules, config) => {
  const {
    AssemblyGuide,
    AssemblyGuideAnchors,
    AssemblyInstruction,
    B2BOrdering,
    BuyGuide,
    BuyGuideAnchors,
    CustomMade,
    HomePage,
    Imprint,
    Media,
    PrintedPoolCloth,

    i18n,
    siteRedirects,
    siteScreens,
    siteTemplates
  } = customModules;

  // the site specific img and SVG directories
  const imgDir = siteId;

  // // arguments passed to the default site
  const passArgs = {
    siteId,
    i18n,
    graphql: graphql(siteId),
    siteScreens,
    siteTemplates
  };

  // override the default imgParams with the site's customization
  const imgParams = {};

  // pass our custom imgParams to site's screens
  const siteCustomScreens = Object.keys(siteScreens).reduce(
    (carry, key) =>
      Object.assign(carry, { [key]: { ...siteScreens[key], imgParams } }),
    {}
  );

  const defaultSite = DefaultSite({ ...passArgs, store: config.store });

  const mergeSiteConfig = defaultSite.utils.mergeSiteConfig;

  const mergedI18n = i18n(siteId, config, mergeSiteConfig);

  const siteDomain = mergedI18n.siteSettings.siteDomain;

  return {
    store: config.store,
    siteId,
    title: siteDomain || `UnknownSiteTitle #${siteId}`,
    siteClassname: (siteDomain || `unknown-site-title-${siteId}`)
      .toLowerCase()
      .replace(/\./g, "-"),
    siteLogo: {
      src: imgDir + "/title.svg"
    },
    // --> JSON dependent settings
    i18n: mergedI18n,
    ...mergeSiteConfig(
      siteId,
      config,
      { siteScreens: siteCustomScreens, siteRedirects },
      "frontend/i18n"
    ),
    // JSON dependent settings <--

    siteTemplates,
    siteStaticContent: {
      assemblyGuideAnchors: AssemblyGuideAnchors,
      assemblyGuide: AssemblyGuide,
      assemblyInstruction: AssemblyInstruction,
      b2bOrdering: B2BOrdering,
      buyGuide: BuyGuide,
      buyGuideAnchors: BuyGuideAnchors,
      customMade: CustomMade,
      homePage: HomePage,
      imprint: Imprint,
      media: Media,
      printedPoolCloth: PrintedPoolCloth
    },
    siteCustomPages: {
      // "assembly-guide": {
      //   ...(siteScreens["assembly-guide"] || { path: "/assembly-guide" }),
      //   screen: () =>
      //     passModuleArgs(
      //       import(/* webpackChunkName: "site" */ "../screens/sites/AssemblyGuidePage-Wine"),
      //       defaultSite.utils.screenPassArgs("assembly-guide")
      //     )
      // },
      // "custom-made": {
      //   ...(siteScreens["custom-made"] || { path: "/custom-made" }),
      //   screen: () =>
      //     passModuleArgs(
      //       import(/* webpackChunkName: "site" */ "../screens/sites/CustomMadePage-Wine"),
      //       defaultSite.utils.screenPassArgs("custom-made")
      //     )
      // }
    },
    widgetsIdentities: {
      // Site/company specific Google reCAPTCHA plugin sitekey
      googleReCaptcha: "6LdBq6kZAAAAAA-fELx9QCJfEYIFqvXv3bNdWA3X"
    }
  };
};

/**
 * @description Merge the site specific GraphQL client initialization arguments
 * @param {number} siteId The site Id
 * @returns {Promise} Returns a promise that resolves the GraphQL client initialization arguments
 */
const mergeGraphQLClientArgs = (siteId, i18n) => ({
  siteId,
  i18n,
  graphql: mergedGraphql(siteId, graphql(siteId))
});

/**
 * @description Merge the the site specific configuration with the given common/custom config
 * @param {Object} customConfig
 * @returns {Promise} Returns a promise that resolves to the site specifc (merged) config
 */
const mergeSiteConfig = (siteId, customModules) => customConfig => {
  const config = options(siteId, customModules, customConfig);
  return Site(
    { ...config, graphql: mergedGraphql(siteId, graphql(siteId)) },
    customConfig
  );
};

export { mergeGraphQLClientArgs, mergeSiteConfig };
