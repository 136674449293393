export default ({ imgParams, i18n }) => {
  const _i18n = i18n.pages.CustomerService;

  return {
    worktime: {
      phone: _i18n.worktime.PHONE,
      worktime: _i18n.worktime.WORKTIME,
      visibleOnPage: _i18n.worktime.visibleOnPage
    },
    address: {
      title: _i18n.address.TITLE,
      subtitle: _i18n.address.SUBTITLE,
      address1: _i18n.address.ADDRESS1,
      address2: _i18n.address.ADDRESS2,
      postalCode: _i18n.address.POSTAL_CODE,
      city: _i18n.address.CITY,
      country: _i18n.address.COUNTRY,
      visibleOnPage: _i18n.address.visibleOnPage
    },
    email: {
      email: _i18n.email.EMAIL,
      text: _i18n.email.TEXT,
      visibleOnPage: _i18n.email.visibleOnPage
    },
    call: {
      title: _i18n.call.TITLE,
      items: [_i18n.call.TEXT],
      visibleOnPage: _i18n.call.visibleOnPage
    }
  };
};
