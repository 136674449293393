import { widgetsAsTemplateItems } from "@templates/common/utils";
import { mergeTrackingWidgets } from "@templates/common/widget-utils";
import { debug } from "@utils/debug";
import {
  ADYEN_PROVIDER_ID,
  getPaymentEnvironment,
  getPaymentEnvironmentId,
  isDevelopment,
  isTestPaymentEnvironment,
  PAYPAL_PROVIDER_ID
} from "@utils/functions";
import { hashValue } from "@utils/strings";
import GraphQLFetch from "../graphql/lib/GraphQLFetch";
import defaultFontAwesomeIcons from "./_default-font-awesome-icons";
import defaultRedirects from "./_default-redirects";
import defaultScreens from "./_default-screens";
import { getPaymentEndpoint, mergedGraphql } from "./_utils";

const paymentCache = {};

const title = "LoremIpsum CMS";

// the arguments are passed by a src/sites/**/index.js call
export default passArgs => {
  const {
    siteId,
    i18n,
    graphql,
    siteScreens,
    siteTemplates,
    imgParams,
    store
  } = passArgs;

  const _mergedGraphql = mergedGraphql(siteId, graphql);

  const paymentEndpoint = getPaymentEndpoint(siteId);

  let apiClient = null;

  const mergedPayment = {
    endpoint: paymentEndpoint,
    /**
     * @description Send the payment request to the server
     * @param {Object} options An object with the following prototype: { action, payload, provider, method, headers ,appStore}
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
     * @returns {Promise} Returns a promise that resolves to an object {json,status}
     */
    fetch: (options, init) => {
      const { action, payload, provider, getState, dispatch, graphqlClient } =
        options;

      // TODO implement a caching mecanism for some queries/options, eg. `payment-methods`

      let cacheKey;

      if (
        "payment-methods" === action &&
        [ADYEN_PROVIDER_ID, PAYPAL_PROVIDER_ID].includes(provider)
      ) {
        cacheKey = hashValue(JSON.stringify(payload));
        const hasCache = "undefined" !== typeof paymentCache[cacheKey];

        if (hasCache) {
          debug(
            `...served from %c${provider}.${action}.CACHE`,
            "log",
            "color: lime; font-weight:600"
          );

          return Promise.resolve(JSON.parse(paymentCache[cacheKey]));
        }
      }

      const env =
        getPaymentEnvironment(provider) ||
        getPaymentEnvironmentId(provider, !isDevelopment());
      const test = isTestPaymentEnvironment(provider, env);

      const url = [paymentEndpoint, test ? env : null, provider, action]
        .filter(Boolean)
        .join("/");

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...init.headers
      };

      if (!apiClient) {
        apiClient = new GraphQLFetch();

        // refresh token via GraphQL API
        apiClient.onFetchNewToken = graphqlClient
          ? (token, userEmail) => graphqlClient.fetchNewToken(token, userEmail)
          : null;

        apiClient.mapStateDispatchToInstance(getState, dispatch);
      }

      const _init = {
        method: "POST",
        keepalive: true,
        ...init,
        url,
        headers,
        transformer: response =>
          response.json().then(json => ({ json, status: response.status }))
      };

      return apiClient.sendToServer(payload, _init).then(result => {
        if (cacheKey && 200 === result.status) {
          paymentCache[cacheKey] = JSON.stringify(result);
        }

        return result;
      });
    }
  };

  // https://developers.google.com/web/fundamentals/performance/resource-prioritization#preconnect
  const preconnectTo = [
    /*_mergedGraphql.endpoint, // <-- already in index.html
    mergedPayment.endpoint, // <-- already in index.html
    "https://api.instacloud.io",
    "http://log.olark.com",
    "http://static.olark.com",
    "https://apps.elfsight.com",
    "https://www.google-analytics.com",
    "https://www.googletagmanager.com"*/
  ];

  const preloadTo = [];

  const preconnectLink = preconnectTo.reduce(
    (carry, url) =>
      carry.concat(
        {
          rel: "preconnect",
          href: url
        },
        {
          rel: "dns-prefetch",
          href: url
        }
      ),
    []
  );

  const preloadLink = preloadTo.reduce(
    (carry, url) =>
      carry.concat({
        rel: "preload",
        href: url
      }),
    []
  );

  const defaultHelmet = {
    title: title,
    meta: {
      name: {
        robots: "index, follow",
        description: "A ReactJS based CMS framework"
      }
    },

    link: [...preconnectLink, ...preloadLink]
  };

  const pages = defaultScreens({
    siteId,
    i18n,
    graphql: _mergedGraphql,
    defaultHelmet,
    defaultWidgets: [], // default widgets, applies to all sites and pages; a site/page will merge its widgets
    siteScreens,
    imgParams,
    store
  });

  const homePageKey = "/";

  const result = {
    // make sure these got defined in the `index.js` of each site's datastore
    /*currencyCode: null,
    currencyPrefix: null,
    currencySuffix: null,*/
    //
    graphql: _mergedGraphql,
    paymentApi: mergedPayment,
    homePageDef: pages.find(page => page.key === homePageKey),
    title: title,
    httpErrors: {
      404: "http-error-404",
      "404-product": "product-http-error-404",
      "404-product-category": "product-category-http-error-404",
      "404-screen": "screen-http-error-404"
    },
    pages,
    templates: siteTemplates,
    className: "lorem-ipsum-cms",
    redirects: defaultRedirects,
    helmet: defaultHelmet,
    mainMenu: null,
    menubarHeader: null,

    security: {
      adminToolbox: false, // allow admin toolbox
      enabled: false, // allow user logging
      enforceStrongPassword: true // strong password
    },
    utils: {
      /**
       * @description Build the site-aware arguments passed to screens.
       * @param {String} key The screen key
       * @returns {Object} Returns the site-aware arguments for screen initialization
       * @see _default-screens.js::siteAwareArgs constant
       */
      screenPassArgs: key => {
        if ("object" !== typeof siteScreens[key]) {
          throw new Error(
            `Cannot find the screen with key "${key}" (see screenPassArgs)`
          );
        }

        const hasItems = prop =>
          "object" === typeof siteScreens[key][prop] &&
          Object.keys(siteScreens[key][prop]).length;

        return {
          siteId: passArgs.siteId,
          i18n: passArgs.i18n,
          graphql: _mergedGraphql,
          screen: {
            path: siteScreens[key].path,
            helmet: hasItems("helmet")
              ? siteScreens[key].helmet
              : defaultHelmet,
            widgets: hasItems("widgets") ? siteScreens[key].widgets : []
          }
        };
      },
      /**
       * @description Merge the site-specific screen configuration with those built-in within screen.
       * @param {Object} screens All screens to be resolved
       * @returns {Array}
       */
      mergeSiteScreens: screens => {
        // override default pages
        const overriden = pages.map(page => {
          const sitePage = screens[page.key] || {};

          // merges the widgets properties with the tracking widget options
          const widgets = mergeTrackingWidgets(
            siteId,
            sitePage.widgets || page.widgets || []
          );

          return {
            ...page,
            path: sitePage.path || page.path,
            helmet: sitePage.helmet || page.helmet,
            features: sitePage.features || page.features,
            widgets: widgetsAsTemplateItems(widgets),
            templates:sitePage.templates
          };
        });

        // join the non-default pages
        return Object.keys(screens)
          .filter(key => !overriden.some(page => page.key === key), overriden)
          .reduce(
            (carry, key) => carry.concat({ key, ...screens[key] }),
            overriden
          );
      },
      /**
       * @description Merge the site-specific redirects with those default
       * @param {Array} siteRedirects The site-specific redirects
       * @returns {Array}
       */
      mergeSiteRedirects: siteRedirects =>
        defaultRedirects
          .filter(
            defItem =>
              siteRedirects &&
              !siteRedirects.some(
                siteItem =>
                  siteItem.from === defItem.from && siteItem.to === defItem.to
              )
          )
          .concat(siteRedirects),
      /**
       * @description Merge the site-specific redirects with those default
       * @param {number} siteId The site Id
       * @param {Object} config The site custom configuration
       * @param {Object} obj The object to be overriden by the custom configuration
       * @param {String} path The path to the custom configuration
       * @returns {Object} Returns the object merged by the custom configuration
       */
      mergeSiteConfig: (siteId, config, obj, path) => {
        if (config) {
          Object.keys(obj).forEach(key => {
            const customConfig = config[`${siteId}/${path}/${key}.json`];

            if ("undefined" !== typeof customConfig) {
              obj[key] = JSON.parse(customConfig);
            }
          });
        }

        return obj;
      },
      /**
       * @description Find a page by its key
       * @param {String} key The page key
       * @param {Array} [screens=null] When given the screens to search, otherwise the default site pages
       * @returns {Object} Returns the found page on success, null otherwise
       */
      findPage: (key, screens = null) =>
        (screens || pages).find(page => key === page.key)
    }
  };

  return result;
};

defaultFontAwesomeIcons.init();
