export default ({ i18n }) => {
  const _i18n = i18n.components.SearchEngine;

  if (!_i18n) {
    return { i18n: { relevance: {} } };
  }

  return {
    type: _i18n.TYPE,
    modal: _i18n.MODAL,
    resultPage: _i18n.RESULT_PAGE,
    adminOnly: _i18n.ADMIN_ONLY,
    i18n: {
      title: _i18n.i18n.TITLE,
      error: _i18n.i18n.ERROR,
      noMatch: _i18n.i18n.NO_MATCH,
      placeholder: _i18n.i18n.PLACEHOLDER,
      exactMatch: _i18n.i18n.EXACT_MATCH,
      buttonClose: _i18n.i18n.BUTTON_CLOSE,
      relevance: {
        title: _i18n.i18n.relevance.TITLE,
        high: _i18n.i18n.relevance.HIGH,
        quite: _i18n.i18n.relevance.QUITE,
        acceptable: _i18n.i18n.relevance.ACCEPTABLE
      }
    }
  };
};
