export default ({ imgParams, pathfinder, i18n }) => {
  const items = i18n.pages.PaymentMethods.items;

  return items.map(item => ({
    ...item,
    url: pathfinder.get(item.url),
    img: {
      ...item.img,
      cloudinary: imgParams.cloudinary,
      src: imgParams.utils.get(imgParams.svgDir, "/" + item.img.src)
    },
    className: "d-inline"
  }));
};
