export default ({ i18n }) => {
  const _i18n = i18n.pages.CheckoutOtherOptions;

  return {
    //className: "my-4",
    setup: { title: _i18n.TITLE },
    assembly: {
      title: _i18n.ASSEMBLY_TITLE
    },
    ordering: {
      title: _i18n.ORDERING_TITLE
    }
  };
};
