import { replaceParams } from "@utils/strings";
import common from "../json/common.json";
import error from "../json/error.json";
import siteSettings from "../json/site-settings.json";
import components from "./components";
import pages from "./pages";

export default (siteId, config, mergeSiteConfig) => {
  const result = {
    components: components(siteId, config, mergeSiteConfig),
    pages: pages(siteId, config, mergeSiteConfig),
    ...mergeSiteConfig(siteId, config, { common }, "frontend/i18n").common,
    ...mergeSiteConfig(siteId, config, { error }, "frontend/i18n").error,
    siteSettings
  };

  return replaceParams(result, siteSettings);
};
