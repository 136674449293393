import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.IntegrityPolicy;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const transformPara = params => ({
    title: params.TITLE,
    text: escapeReactRoute(params.TEXT)
  });

  const transformTable = obj => ({
    lead: {
      title: obj.lead.TITLE,
      text: obj.lead.TEXT
    },
    table: {
      visible: obj.table.visible,
      header: (obj.table.header || []).map(item => item.TITLE),
      rows: (obj.table.rows || []).map(item => item.cols.map(col => col.TEXT))
    }
  });

  return {
    title: _i18n.TITLE,
    paragraphs_1: _i18n.paragraphs_1.map(transformPara),
    customerTable: transformTable(_i18n.customerTable),
    visitorTable: transformTable(_i18n.visitorTable),
    paragraphs_2: _i18n.paragraphs_2.map(transformPara),
    thirdPartyTable: transformTable(_i18n.thirdPartyTable),
    rights: {
      lead: transformPara(_i18n.rights.lead),
      items: _i18n.rights.items.map(transformPara)
    },
    paragraphs_3: _i18n.paragraphs_3.map(transformPara),
    aboutCompany: {
      name: _i18n.aboutCompany.NAME,
      organizationNo: _i18n.aboutCompany.ORG_NO,
      address: _i18n.aboutCompany.ADDRESS,
      email: _i18n.aboutCompany.EMAIL,
      contact: {
        name: _i18n.aboutCompany.contact.NAME,
        email: _i18n.aboutCompany.contact.EMAIL,
        phone: _i18n.aboutCompany.contact.PHONE
      }
    }
  };
};
