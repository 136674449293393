import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.AssemblyGuide;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  return {
    lead: {
      header: _i18n.lead.HEADER,
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT),
      img: {
        src: imgParams.imgDir + "/pages/assembly-guide/".concat(_i18n.lead.IMG),
        importance: "high",
        cloudinary: imgParams.cloudinary,
        sizes: {
          mobilePortrait: 345,
          mobileLandscape: 480,
          tabletPortrait: 315,
          tabletLandscape: 435,
          desktop: 540
        },
        aspect: 0.351,
        version: true,
        title: _i18n.lead.TITLE
      }
    },
    installChecklist: {
      className: "bg-light",
      title: _i18n.installChecklist.TITLE,
      items: _i18n.installChecklist.paragraphs.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        className: "article",
        img: item.IMG
          ? {
              src: imgParams.imgDir + "/pages/assembly-guide/".concat(item.IMG),
              importance: "high",
              cloudinary: imgParams.cloudinary,
              sizes: {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              aspect: 0.351,
              version: true,
              title: item.TITLE
            }
          : null
      }))
    },
    mediaList: {
      colspan: _i18n.mediaList.COLSPAN,
      title: _i18n.mediaList.TITLE,
      text: escapeReactRoute(_i18n.mediaList.TEXT),
      items: _i18n.mediaList.items.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        url: item.URL,
        allowfullscreen: true,
        titleAs: "h4",
        sizes: {
          mobilePortrait: 285,
          mobileLandscape: 450,
          tabletPortrait: 285,
          tabletLandscape: 405,
          desktop: 495
        }
      }))
    },
    otherParagraphs: {
      items: _i18n.otherParagraphs.items.map(item => ({
        title: item.TITLE,
        titleAs: "h4",
        text: escapeReactRoute(item.TEXT)
      }))
    }
  };
};
