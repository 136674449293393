import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_CATEGORY
} from "@constants";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.SearchBanner;

  const getProductCategoryPath = categoryId =>
    findPathBySelectorType(
      pathfinder,
      PRODUCT_SELECTOR_TYPE_CATEGORY,
      categoryId
    );

  const buildSearchBannerItem = index => ({
    title: _i18n.items[index].TITLE,
    text: _i18n.items[index].TEXT,
    url: getProductCategoryPath(_i18n.items[index].CATEGORY_SLUG),
    img: {
      src:
        imgParams.imgDir +
        "/common/bottom-product-carddeck/".concat("card-" + (index + 1)),
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 336,
        mobileLandscape: 501,
        tabletPortrait: 221,
        tabletLandscape: 301,
        desktop: 361
      },
      aspect: 0.6,
      version: true
    }
  });

  return {
    title: _i18n.TITLE,
    items: _i18n.items.map((item, i) => buildSearchBannerItem(i))
  };
};
