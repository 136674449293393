import { getStoreKey } from "@utils/functions";

export default pageParams => {
  const _i18n = pageParams.i18n.components.GDPRCookieController;

  return {
    enabled: _i18n.ENABLED,
    title: _i18n.TITLE,
    description: _i18n.DESCRIPTION,
    consent: _i18n.CONSENT,
    cookiePolicyUrl: _i18n.COOKIE_POLICY_URL,
    privacyPolicyUrl: _i18n.PRIVACY_POLICY_URL,
    aboutCookie: _i18n.ABOUT_COOKIE,
    whatIsCookie: _i18n.WHAT_IS_COOKIE,
    allowRibbon: _i18n.ALLOW_RIBBON,
    allowBackdrop: _i18n.ALLOW_BACKDROP,
    allowResize: _i18n.ALLOW_RESIZE,
    allowSelection: _i18n.ALLOW_SELECTION,
    collapsedSelection: _i18n.COLLAPSED_SELECTION,
    allowDetails: _i18n.ALLOW_DETAILS,
    consentOnly: _i18n.MODE_MINIMAL,
    enforceAcceptAll: _i18n.ENFORCE_ACCEPT_ALL,
    enforceChoose: _i18n.ENFORCE_CHOOSE,
    defaultTheme: _i18n.THEME,
    position: _i18n.POSITION,
    buttons: {
      ..._i18n.buttons,
      READ_MORE: {
        url: "https://gdpr.eu/cookies",
        title: pageParams.i18n.BTN_READ_MORE
      }
    },
    cookieSiteId: _i18n.THIS_SITE,
    cookieSiteKey: getStoreKey(false) + "-*",
    cookieDatabase: _i18n.cookieDatabase,
    expiry: _i18n.expiry,
    noCookie: _i18n.NO_COOKIE,
    purpose: Object.keys(_i18n.purpose).map(id => ({
      id: _i18n.purpose[id].KEY,
      title: _i18n.purpose[id].TITLE,
      description: _i18n.purpose[id].DESCRIPTION
    }))
  };
};
