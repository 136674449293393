import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.CustomerService;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  return {
    className: "img-fluid",
    img: {
      src: imgParams.utils.get(
        imgParams.imgDir,
        "/pages/customer-service/".concat(_i18n.lead.IMG)
      ),
      importance: "high",
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 315,
        mobileLandscape: 480,
        tabletPortrait: 315,
        tabletLandscape: 435,
        desktop: 525
      },
      aspect: 0.61,
      version: true
    },
    title: _i18n.lead.TITLE,
    text: escapeReactRoute(_i18n.lead.TEXT)
  };
};
