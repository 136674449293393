import React from "react";
import { badgify } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.Error404;

  const errorProps = (key, errorDef) => ({
    title: _i18n[key].TITLE.replace("%search_key%", errorDef.searchKey),
    icon: "bug",
    className: "text-danger",
    text: (
      <React.Fragment>
        <p className="">
          {badgify(_i18n[key].TEXT, "search_key_badge", {
            variant: "danger",
            children: errorDef.searchKey
          })}
        </p>
        {errorDef.items ? <ul>{errorDef.items}</ul> : null}
      </React.Fragment>
    )
  });

  return (key, searchKey, items) => errorProps(key, { items, searchKey });
};
