import { escapeReact } from "@utils/react";

export default pageParams => {
  const { i18n, pathfinder } = pageParams;

  const _i18n = i18n.pages.ProductBrandInfo;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  return {
    title: _i18n.TITLE,
    text: escapeReactRoute(_i18n.TEXT)
  };
};
