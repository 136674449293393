import { getLoginStatus } from "@redux-utils";
import { passModuleArgs } from "@templates/utils";
import {
  allowAdminToolbox,
  allowUserLogin,
  isAdminConfig
} from "@utils/functions";
import deepmerge from "deepmerge";
import {
  ROUTE_ROLE_ADMIN,
  ROUTE_ROLE_CHECKOUT,
  ROUTE_ROLE_ERROR,
  ROUTE_ROLE_NAVIGATION,
  ROUTE_ROLE_SEARCH,
  ROUTE_ROLE_SECURITY
} from "../constants";
import { loadScreen } from "../screens/utils";

export default passArgs => {
  //const { siteId, i18n, graphql, defaultHelmet, defaultWidgets, siteScreens, imgParams, store} = passArgs;

  // NOTE: the userLogin may be undefined at PAGE_LOAD (ie. before the USER_LOGIN_REFRESH_TOKEN_SUCCESS action is dispatched)

  const loginStatus = getLoginStatus(passArgs.store.getState().userLogin);
  const trusted = (loginStatus.loggedUser || {}).trusted || isAdminConfig();

  const getScreenByRole = role => {
    const screens = passArgs.siteScreens;
    const keys = Object.keys(passArgs.siteScreens);

    return keys
      .filter(key => role === screens[key].role)
      .map(key => ({
        key,
        path: screens[key].path,
        role: screens[key].role,
        file: screens[key].file
      }));
  };

  const screens = [
    ...getScreenByRole(ROUTE_ROLE_NAVIGATION),
    ...getScreenByRole(ROUTE_ROLE_CHECKOUT),
    ...getScreenByRole(ROUTE_ROLE_ERROR),
    ...getScreenByRole(ROUTE_ROLE_SEARCH)
  ];

  if (trusted || allowUserLogin(passArgs.siteId)) {
    screens.push(...getScreenByRole(ROUTE_ROLE_SECURITY));
  }

  if (trusted || allowAdminToolbox(passArgs.siteId)) {
    screens.push(...getScreenByRole(ROUTE_ROLE_ADMIN));
  }

  // merge the JSON settings into the default screen
  return screens.map(screen => {
    const mergedHelmet = deepmerge(
      passArgs.defaultHelmet || {},
      screen.helmet || {}
    );
    const mergedWidgets = deepmerge(
      passArgs.defaultWidgets || [],
      screen.widgets || []
    );

    // site-aware arguments passed to screens (see also _default.js::screenPassArgs())
    const siteAwareArgs = {
      siteId: passArgs.siteId,
      i18n: passArgs.i18n,
      graphql: passArgs.graphql,
      screen: passArgs.siteScreens[screen.key] || {},
      imgParams: passArgs.imgParams
    };

    return {
      // safe values
      payload: undefined,
      key: undefined,
      // default values
      ...screen,
      // merged values
      screen: () => {
        const modulePromise = screen.file
          ? Promise.resolve(loadScreen(screen.file))
          : screen.resolver
          ? screen.resolver()
          : Promise.reject(
              new Error(
                `Neither screen resolver/file not specified for screen with key "${screen.key}"`
              )
            );

        return passModuleArgs(modulePromise, siteAwareArgs);
      },
      helmet: mergedHelmet,
      widgets: mergedWidgets,
      path: passArgs.siteScreens[screen.key]
        ? passArgs.siteScreens[screen.key].path
        : screen.path,
      custom:
        (passArgs.siteScreens[screen.key]
          ? passArgs.siteScreens[screen.key].custom
          : screen.custom) || false
    };
  });
};
