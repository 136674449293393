import Package from "../../../package.json";
import FooterLinkSections from "./FooterLinkSections";

export default pageParams => {
  const { i18n, pathfinder, paymentMethods, title } = pageParams;

  const _i18n = i18n.pages.FooterLinkSections;

  const brandSection = {
    caption: title,
    url: pathfinder.get("/"),
    siteOwner: Package.author.company,
    copyright: { from: 2019, to: null },
    sitePayMethods: _i18n.SHOW_PAYMENT_METHODS ? paymentMethods : []
  };

  return {
    brandSection: brandSection,
    linkSection: FooterLinkSections(pageParams)
  };
};
