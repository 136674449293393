import { getRoutePathByItem } from "@constants";
import { mergeTrackingWidgets } from "@templates/common/widget-utils";
import { escapeReact } from "@utils/react";

export default ({ siteId, imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.FooterLinkSections;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const resolveUrl = (url, pathfinder) =>
    /^https?:\/\//.test(url) ? url : pathfinder.get(url);

  const productCategories = {
    title: _i18n.productCategory.TITLE,
    xs: 6,
    items: _i18n.productCategory.items.map(item => ({
      caption: item.TITLE,
      url: getRoutePathByItem(pathfinder, item)
    }))
  };

  const siteInfo = {
    title: _i18n.info.TITLE,
    xs: 6,
    items: _i18n.info.items.map(item => ({
      caption: item.TITLE,
      url: resolveUrl(item.URL, pathfinder)
    }))
  };

  const contactInfo = {
    title: _i18n.contact.TITLE,
    xs: 12,
    items: _i18n.contact.items
      .map(item => ({
        label: item.TITLE,
        caption: item.TEXT,
        url: item.URL
      }))
      .concat({
        class: "contact-brand px-0",
        items: _i18n.contact.icons.map(icon => ({
          title: icon.TITLE,
          url: resolveUrl(icon.URL, pathfinder),
          icon: Object.values(icon.ICON),
          img: icon.IMG
            ? {
                src: imgParams.utils.get(imgParams.svgDir, "/" + icon.IMG),
                cloudinary: imgParams.cloudinary,
                aspect: 1,
                sizes: { any: 32 }
              }
            : null
        }))
      })
  };

  return {
    items: [productCategories, siteInfo, contactInfo],
    description: {
      title: _i18n.TITLE,
      xs: 12,
      text: escapeReactRoute(_i18n.TEXT)
    },
    // merges the widgets properties with the tracking widget options
    widgets: mergeTrackingWidgets(siteId, _i18n.widgets)
  };
};
