import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.CustomerService;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  return {
    title: _i18n.footnote.TITLE,
    text: escapeReactRoute(_i18n.footnote.TEXT)
  };
};
