export default ({ i18n }) => {
  const _i18n = i18n.pages.CheckoutRelatedProducts;

  return {
    title: _i18n.TITLE,
    text: _i18n.TEXT,
    itemsPerSlide: 4,
    imgSize: { maxHeight: "160px" },
    items: [
      // {
      //   text: "Lofra - Handtag till Ugnspanna Dolce vita 60 cm",
      //   title: "Fläktar",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie1.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/1"
      // },
      // {
      //   text: "Lofra - Wokring",
      //   title: "Stänkskydd",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie2.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/2"
      // },
      // {
      //   text: "Lofra - Gjutjärnsplatta",
      //   title: "Plåtar",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie3.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/3"
      // },
      // {
      //   text: "Lofra - Teppanyaki stekbord för gasspis",
      //   title: "Galler",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie4.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/4"
      // },
      // {
      //   text: "Lofra - Teppanyaki stekbord för keramisk häll",
      //   title: "Övrigt",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie5.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/5"
      // },
      // {
      //   text: "Lofra - Sockel (H: 10 cm)",
      //   title: "Lorem Ipsum",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie6.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/6"
      // },
      // {
      //   text: "Lofra - Sockel (H: 13,5 cm)",
      //   title: "Något annat",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie7.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/7"
      // },
      // {
      //   text: "Lofra - Kolfilter till Dolce Vita fläktar - 2 pack",
      //   title: "Bla-bla-bla",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie8.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/8"
      // },
      // {
      //   text: "Lofra - Teleskopfot till Dolce Vita (extra hög) 4-pack",
      //   title: "Nej! Igen?",
      //   img: {
      //     src: imgParams.utils.get(imgParams.imgDir , "/accessories/accessorie9.jpg"),
      //     cloudinary: imgParams.cloudinary,
      //     sizes: {
      //       desktop: 198,
      //       tabletPortrait: 196,
      //       tabletLandscape: 205,
      //       mobilePortrait: 142,
      //       mobileLandscape: 224
      //     },
      //     aspect: 0.81
      //   },
      //   url: "/accessorie/9"
      // }
    ]
  };
};
