import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCss3,
  faFacebookF,
  faGitAlt,
  faGoogle,
  faInstagram,
  faSearchengin,
  faTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

import {
  faAdjust,
  faAnchor,
  faAngleRight,
  faArchive,
  faArrowsAltH,
  faBackspace,
  faBalanceScaleRight,
  faBars,
  faBorderAll,
  faBug,
  faCartArrowDown,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCloudUploadAlt,
  faCode,
  faCodeBranch,
  faCog,
  faCommentDots,
  faCopy,
  faCreditCard,
  faCrosshairs,
  faDatabase,
  faDivide,
  faDownload,
  faEdit,
  faEnvelope,
  faEraser,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileWord,
  faFilm,
  faFilter,
  faGlobeAmericas,
  faGripHorizontal,
  faGripLines,
  faGripLinesVertical,
  faHardHat,
  faHeart,
  faHistory,
  faHome,
  faIdCard,
  faImage,
  faImages,
  faInfoCircle,
  faKey,
  faLanguage,
  faLayerGroup,
  faLightbulb,
  faLink,
  faMagic,
  faMapMarkerAlt,
  faMinus,
  faPaste,
  faPhoneVolume,
  faPlay,
  faPlus,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestionCircle,
  faRandom,
  faRibbon,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faServer,
  faShippingFast,
  faShoppingCart,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faStarHalfAlt,
  faStopwatch,
  faSurprise,
  faSyncAlt,
  faTable,
  faTachometerAlt,
  faTerminal,
  faThList,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faTruck,
  faTv,
  faUndo,
  faUser,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

// this should contain the non-admin icons only
const items = [
  faAdjust,
  faAnchor,
  faAngleRight,
  faArchive,
  faArrowsAltH,
  faBackspace,
  faBalanceScaleRight,
  faBars,
  faBorderAll,
  faBug,
  faCartArrowDown,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCloudUploadAlt,
  faCode,
  faCodeBranch,
  faCog,
  faCommentDots,
  faCopy,
  faCreditCard,
  faCrosshairs,
  faCss3,
  faDatabase,
  faDivide,
  faDownload,
  faEdit,
  faEnvelope,
  faEraser,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faExpandArrowsAlt,
  faFacebookF,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileWord,
  faFilm,
  faFilter,
  faGitAlt,
  faGlobeAmericas,
  faGoogle,
  faGripHorizontal,
  faGripLines,
  faGripLinesVertical,
  faHardHat,
  faHeart,
  faHistory,
  faHome,
  faIdCard,
  faImage,
  faImages,
  faInfoCircle,
  faInstagram,
  faKey,
  faLanguage,
  faLayerGroup,
  faLightbulb,
  faLink,
  faMagic,
  faMapMarkerAlt,
  faMinus,
  faPaste,
  faPhoneVolume,
  faPlay,
  faPlus,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faRibbon,
  faRandom,
  faQuestionCircle,
  faSave,
  faSearch,
  faSearchengin,
  faSearchMinus,
  faSearchPlus,
  faServer,
  faShippingFast,
  faShoppingCart,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faStarHalfAlt,
  faStopwatch,
  faSurprise,
  faSyncAlt,
  faTable,
  faTachometerAlt,
  faTerminal,
  faThList,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faTruck,
  faTv,
  faTwitter,
  faUndo,
  faUser,
  faUsers,
  faYoutube
];

let adminItems = [];

try {
  adminItems = require("./_default-admin-font-awesome-icons").items;
} catch (error) {}

export default {
  items: [...new Set(items.concat(...adminItems))],
  init: () => library.add(...items)
};
