export default ({ imgParams, pathfinder, i18n }) => {
  const CART_BUTTON_TYPE = "cart";
  const USER_BUTTON_TYPE = "user";
  const ADMIN_BUTTON_TYPE = "admin";
  const SEARCH_BUTTON_TYPE = "search";
  const FAVORITE_BUTTON_TYPE = "favorite";

  return i18n.pages.MenuBarHeader.filter(
    button =>
      [SEARCH_BUTTON_TYPE, FAVORITE_BUTTON_TYPE].includes(button.type) ||
      pathfinder.get(button.url, null, pathfinder.BOOL_ON_NOTFOUND)
  ).map(button => ({
    role: button.type,
    title: button.title,
    icon: button.icon,
    href: button.url.startsWith("#")
      ? button.url
      : pathfinder.generate(button.url),
    isCartButton: CART_BUTTON_TYPE === button.type,
    isUserButton: USER_BUTTON_TYPE === button.type,
    isAdminButton: ADMIN_BUTTON_TYPE === button.type,
    isSearchButton: SEARCH_BUTTON_TYPE === button.type,
    isFavoriteButton: FAVORITE_BUTTON_TYPE === button.type
  }));
};
