import { debug } from "@utils/debug";

export default ({ i18n }) => {
  const _i18n = i18n.pages.CheckoutShoppingCart;

  return {
    //className: "my-4",
    setup: {
      // see ShoppingCart/ShoppingCart.js
      cart: {
        title: _i18n.CART_TITLE,
        text: "Steg 1 - ".concat(_i18n.CART_TEXT)
      },
      items: {
        // see ShoppingCart/Item.js
        name: _i18n.ITEMS_NAME,
        quantity: _i18n.ITEMS_QTY,
        price: _i18n.ITEMS_PRICE,
        amount: _i18n.ITEMS_AMOUNT,
        imgSize: {
          maxWidth: "260px",
          maxHeight: "232px"
        }
      },
      // see ShoppingCart/ShoppingCart.js
      comment: {
        id: "order-comment",
        label: _i18n.COMMENT_LABEL,
        value: null
      },
      // see ShoppingCart/ShoppingCart.js
      couponWidget: {
        enabled: _i18n.coupon.ENABLED,
        title: _i18n.coupon.COUPON_TITLE,
        maxItems: _i18n.coupon.MAX_ITEMS || 1,
        widget: {
          id: "add-order-coupon",
          title: _i18n.coupon.COUPON_BTN,
          text: _i18n.coupon.COUPON_PLACEHOLDER,
          onAdd: e => {
            debug(e, "debug");
          }
        }
      },
      // see ShoppingCart/ShoppingCart.js
      couponsItems: {
        title: _i18n.coupon.COUPON_ITEMS_TITLE
      }
    }
  };
};
