export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.ImageFootnoteCardDeck;

  const buildItem = item => ({
    title: item.TITLE,
    text: item.TEXT,
    url: pathfinder.get(item.URL),
    img: {
      src: imgParams.utils.get(
        imgParams.imgDir,
        "/common/bottom-menu-carddeck/".concat(item.IMG)
      ),
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 336,
        mobileLandscape: 501,
        tabletPortrait: 221,
        tabletLandscape: 301,
        desktop: 361
      },
      aspect: 0.6,
      version: true
    }
  });

  let items = _i18n.items.map(buildItem);

  const result = { title: _i18n.TITLE, items };

  if (imgParams.colspan) {
    result.items = items.map((item, i) => {
      item.params.colspan = imgParams.colspan[i];
      return item;
    });
  }

  return result;
};
