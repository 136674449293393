export default ({ imgParams, i18n }) => {
  const _i18n = i18n.pages.BuyGuide;

  const anchors = Object.keys(_i18n)
    .filter(
      key =>
        [
          "servingTemp",
          "goldenRules",
          "featuredProductCategory",
          "faq"
        ].indexOf(key) !== -1
    )
    .map(key => _i18n[key].anchor);

  const buildItem = () => ({
    img: {
      importance: "high",
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 301,
        mobileLandscape: 466,
        tabletPortrait: 128,
        tabletLandscape: 188,
        desktop: 234
      },
      aspect: 1.045,
      version: true
    },
    text: null
  });

  const items = [buildItem(), buildItem(), buildItem(), buildItem()];

  return {
    items: items
      .map((item, i) => ({
        ...item,
        title: anchors[i].TITLE,
        //url: "#anchor-" + i,
        img: anchors[i].IMG
          ? {
              ...item.img,
              src: imgParams.imgDir + "/pages/buy-guide/".concat(anchors[i].IMG)
            }
          : { as: "div", style: { minHeight: "279px" } }
      }))
      .filter(item => item.title || item.text || item.img.src)
  };
};
